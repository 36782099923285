body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,html{
  height: 100%;
  width: 100%;
  /* font-family: 'PT Mono', monospace; */
  font-family: "Montserrat";
}

/* body::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
} */

div#preload {
  display: none;
}

#title-name {
  font-size: 4rem;
  font-weight: 600;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, .15);
  letter-spacing: .5rem;
}
#mellow-title-name {
  font-size: 2rem;
  font-weight: 500;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, .15);
  letter-spacing: .25rem;
}
.mellow-banner-text {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  font-size: larger;
  letter-spacing: .25rem;
}



#landingCenter {
  /* background: radial-gradient( 
    closest-side, 
    rgba(255,255,255, 1), 
    rgba(255,255,255, .75), 
    rgba(255,255,255, .5),  
    rgba(255,255,255, .25),
    rgba(255,255,255, .1) ); */
  background: rgba(255,255,255, .5); 
  position: fixed;
  top: 40%;
  left: 50%;
  width: 100%;
  height: 150vh;
  transform: translate(-50%,-50%);
  border-radius: 50px;
}

#landingJumbo{
  background: none !important;
  position: absolute;
  top: 40%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 50px;
}

#landingButton {
  color: #fff !important;
  text-decoration: none;
  background: rgba(32,29,24, .8);
  height: 2rem;
  width: 8rem;
  border-radius: 1rem;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  letter-spacing: .15rem;
  font-family: "Montserrat";
}

#landingButton:hover {
  cursor: pointer;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  transition: all 0.4s ease 0s;
}

#landingButton:focus {     
  outline:none;
  background-color: rgba(32,29,24, 1);;    
}

.back-btn {
  position: absolute !important;
  background: rgba(32,29,24, .2) !important;
  top: 10px;
  right: 10px;
}

.fade-out {
  opacity: 0.01;
}

.fade-in {
  opacity: 1;
  transition: opacity 500ms ease-in;
}


.white-in {
  position: fixed;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
  opacity: 0;
}

.white-out {
  background-color: #fff;
  height: 100vh;
  z-index: 100;
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.faded-out {
  height: 500vh;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  opacity: 1;
}
.faded-in {
  position: fixed;
  background-color: #fff;
  height: 500vh;
  width: 100%;
  z-index: 100;
  opacity: 0;
  transition: opacity 500ms ease-in;
}


@media only screen and (max-width: 600px) {
  #title-name {
    font-size: 2rem;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    letter-spacing: .25rem;
  }
  #mellow-title-name {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    letter-spacing: .15rem;
  }
  #landingCenter {
    /* background: radial-gradient( 
      closest-side, 
      rgba(255,255,255, 1), 
      rgba(255,255,255, .85), 
      rgba(255,255,255, .75), 
      rgba(255,255,255, .65),
      rgba(255,255,255, .55) 
    ); */
    background: rgba(255,255,255, .5); 
    position: fixed;
    top: 40%;
    left: 50%;
    width: 100%;
    height: 150vh;
    transform: translate(-50%,-50%);
    border-radius: 50px;
  }

}